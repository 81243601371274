import React from "react"
import News from "../../../blocks/en/news_page"
import { Link, graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "ninua_loan_to_anorthosis" }) {
    ...NewsInfoEn
  }
}`

export default class NewsTextEn extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Catenaccio Sports Agency is happy to announce the agreement between Anorthosis Famagusta and Nika Ninua, acquired on a one-year loan from PAOK Thessaloniki. The player preferred to compete under number 32.</p>

          <p>Ninua was born on June 22nd, 1999 in Tbilisi, Georgia, and trained at the academies of the country's most successful team, Dinamo Tbilisi. He was promoted to the first team in 2017 and played in 99 official matches, scoring 14 goals. In 2020, he moved to PAOK Thessaloniki. Last season Nika played 12 games and scored two goals.</p>
        </div>
      </div>
    )
  }
}